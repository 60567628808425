
.post-card {
    position: relative;
    border-radius: 0.5em;
    height: 50vh !important;

    .section-blog & {
        height: 40vh;
    }

    @media (max-width: 425px) {
        height: 50vh !important;

        .section-blog & {
            height: 60vh !important;
        }
    }

    @media (min-width: 426px) and (max-width: 768px) {
        height: 65vh !important;
        .section-blog & {
        }
    }

    @media (min-width: 768px) and (max-width: 992px) {
        height: 60vh !important;
        
        .section-blog & {
            height: 35vh !important;
        }
    }

    .img-container {
        border-radius: 0.5em;
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        background-color: rgba(0, 0, 0, 0.15);

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .card-body {
        padding: 0.5rem;

        .card-title {
            margin: 0;
            height: 25%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 1em;
            font-weight: 600;
            color: $primary;
        }

        .card-subtitle {
            margin: 0;
            height: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-topics {
            height: auto;
        }
    }

    .card-footer {
        height: 40px;
    }
}


.card {
    position: relative;
    border-radius: 0.5em;
    
    .card-img {
        
        border-radius: 0.5em;
    }
  &.card-plain {
    background: transparent;
    box-shadow: none;
    border: none;
  }
}

.profile-page {
    .card-profile {
        margin-top: -150px;

        .card-profile-image {
            position: relative;
            min-height: 130px;

            img {
                max-height: 30vh;
                border-radius: 2em !important;
                box-shadow: none;
                transform: translate(-50%,-50%);
                position: absolute;
                left: 50%;
            }
        }

        .card-profile-stats {
            padding: 1rem 0;

            > div {
                text-align: center;
                margin-right: 1rem;
                padding: .875rem;

                &:last-child {
                    margin-right: 0;
                }

                .heading {
                    font-size: 1.1rem;
                    font-weight: bold;
                    display: block;
                }
                .description {
                    font-size: .875rem;
                    color: $gray-500;
                }
            }
        }

        .card-profile-actions {
            padding: .875rem;
        }

        @include media-breakpoint-down(xs) {
            .card-profile-actions {
                margin-top: 110px;
            }
        }

        @include media-breakpoint-between(sm, md) {
            .card-profile-stats {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 990px) {
    .profile-page .card-profile .card-profile-image img {
        max-width: 50vw;
    }
}

@media screen and (max-width: 767px) {
    .profile-page .card-profile .card-profile-image img {
        max-width: 80vw;
    }
}

// Card with blockquotes

.card {
  .card-blockquote {
    padding: 2rem;
    position: relative;

    .svg-bg {
        display: block;
        width: 100%;
        height: 95px;
        position: absolute;
        top: -94px;
        left: 0;
    }
  }
}

// Animated cards

.card-lift--hover {
    &:hover {
        transform: translateY(-20px);
        @include transition($transition-base);
    }
}
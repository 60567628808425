
.ql-align-justify {
    text-align: justify;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}


.postt {
  min-height: 80vh;
  .post-img {
    justify-content: center;
    img {
      width: 100%;
      max-height: 616px;
      border: 1px inset black;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
                  0 6px 20px rgba(0, 0, 0, 0.1); 
    }
  }
  .post-content{
    all: initial;
    font-family: inherit;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .under-img {
    margin-left: 0;
    margin-right: 0;
    padding: 5px;
    .socials {
      a {
        padding: 5px;
        font-size: 25px;
        color: black;
        svg {
          transition: transform ease 0.3s, color ease 0.3s;
        }
        
        &:hover {
          svg {
            transform: scale(1.1);
            color: mix(white, black, 25%);
          }
        }
        
        &:active {
          svg {
            transform: scale(0.85);
            color: mix(white, black, 75%);

          }
        }
      }
    }
    .date {
      margin-left: auto;
      font-size: small;
    }
  }
  
}

.post-image img {
  overflow: visible;
  width: 100%;
  max-height: 616px;
  border: 1px inset black;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 6px 20px rgba(0, 0, 0, 0.1); 

  &:hover {
    transform: scale(1.01); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2); 
  }
}

